/* You can add global styles to this file, and also import other style files */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import "./assets/scss/app.scss";
@import "@ng-select/ng-select/themes/default.theme.css";

/* Disable horizontal scrolling */
body {
    overflow-x: hidden;
}

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.dataTables_empty {
    display: none;
}

// Fix UI issues
.cal-day-headers {
    position: sticky;
    top: 3.37rem;
    z-index: 5;
    background-color: #fff;
}

@media screen and (min-width: 768px) {
    .cal-day-headers {
        top: 3.7rem;
    }
}

@media screen and (min-width: 992px) {
    .cal-day-headers {
        top: 4.9rem;
    }
}

.selected-item {
    max-width: initial !important;
}

table {
    tr .suspense {
        position: -webkit-sticky;
        /* Safari */
        position: sticky !important;
        top: 0;
        left: 0;
        z-index: 5;
        background-color: #fff !important;
    }

    th,
    td {
        white-space: nowrap;
        max-width: 5rem;
        overflow-x: auto;
    }

    @media screen and (min-width: 768px) {

        th,
        td {
            max-width: 15rem;
        }
    }

    th.actions,
    td.actions {
        max-width: unset;
    }

    .bg-pending {
        color: #ff3364;
        /* Bootstrap yellow color */
        font-size: 16px;
        width: 95px;
        background-color: #f7e7eb !important;

        td {
            color: inherit !important;
        }

        td.suspense {
            background-color: inherit !important;
        }
    }

    .bg-received {
        color: #54ba4a;
        /* Bootstrap yellow color */
        font-size: 16px;
        width: 100px;
        background-color: #deeddc !important;

        td {
            color: inherit !important;
        }

        td.suspense {
            background-color: inherit !important;
        }
    }

    .bg-completed {
        color: #54ba4a;
        /* Bootstrap yellow color */
        font-size: 16px;
        width: 118px;
        background-color: #deeddc !important;

        td {
            color: inherit !important;
        }

        td.suspense {
            background-color: inherit !important;
        }
    }

    .bg-collected {
        color: #54ba4a;
        /* Bootstrap yellow color */
        font-size: 16px;
        width: 209px;
        background-color: #deeddc !important;

        td {
            color: inherit !important;
        }

        td.suspense {
            background-color: inherit !important;
        }
    }

    .bg-progress {
        color: black;
        /* Bootstrap yellow color */
        font-size: 16px;
        width: 120px;
        background-color: #dff9ba !important;

        td {
            color: inherit !important;
        }

        td.suspense {
            background-color: inherit !important;
        }
    }

    .bg-cancelled {
        color: rgb(227, 142, 142);
        /* Bootstrap yellow color */
        font-size: 16px;
        width: 117px;
        background-color: #f0c9c9 !important;

        td {
            color: inherit !important;
        }

        td.suspense {
            background-color: inherit !important;
        }
    }
}

.dropdown-menu {
    z-index: 6 !important;
}
